.about-us-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    text-align: left;
  }
  
  .about-us-container h2,
  .about-us-container h3 {
    color: #333;
  }
  
  .about-us-container p {
    color: #666;
    line-height: 1.6;
  }
  
  .about-us-container strong {
    font-weight: bold;
  }
  
  .about-us-container h2,
  .about-us-container h3,
  .about-us-container p {
    margin-bottom: 10px;
  }
  
  /* Style for the photo container */
  .about-us-photo {
    text-align: center; /* Center align the photo */
  }
  
  /* Style for the photo */
    .about-us-image {
        max-width: 80%; /* Adjust the max-width to make the image smaller */
        height: auto;
        border-radius: 8px; /* Add border radius for a rounded look */
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); /* Add shadow for depth */
        margin-bottom: 20px; /* Add some space below the photo */
  }
  
  .about-us-image2 {
    max-width: 75%; /* Adjust the max-width to make the image smaller */
    height: auto;
    border-radius: 8px; /* Add border radius for a rounded look */
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); /* Add shadow for depth */
    margin-bottom: 20px; /* Add some space below the photo */
}
  