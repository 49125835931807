.socialMediaMarketing {
  position: relative;
}

.picture {
  width: 100%;
  height: auto;
  position: relative;
  top: 0;
  left: 0;
  opacity: 100%;
  clip-path: polygon(70% 0%, 100% 0%, 100% 100%, 20% 100%); /* Reversed clip path shape */
}

.picture.fadeIn {
  opacity: 100%;
}

.serviceText {
  opacity: 0;
  transition: opacity 0.5s ease, left 0.5s ease; /* Added left transition */
  position: fixed;
  top: 50%;
  left: -100%; /* Initial position off-screen from the left */
  transform: translate(-50%, -50%);
  z-index: 1;
  font-size: 24px;
  white-space: nowrap; /* Prevent line breaks */
}

.serviceText.visible {
  opacity: 1;
  left: 10%; /* Move text to the center when visible */
}

@media only screen and (max-width: 768px) {
  .serviceText {
    font-size: 8px; /* Decrease font size for better visibility on smaller screens */
  }
}
