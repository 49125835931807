/* Logo.module.css */

.logoContainer {
  margin-right: 10px; /* Add margin to the right */
}

.logoPicture {
  width: 100px; /* Set the width of the image */
  height: 50px; /* Set the height of the image */
}

.name {
  flex-grow: 1; /* Allow the name to grow to fill available space */
  text-align: center; /* Center the text */
  margin-right: 50px;
  font-family: 'Poppins', sans-serif;
}

.header {
  display: flex; /* Use flexbox to align items */
  align-items: center; /* Vertically center items */
  background-color: #333;
  color: #fff;
  padding: 5px;
  position: relative; /* Required for absolute positioning of the menu */
}

.menuButton {
  cursor: pointer;
  padding: 15px; /* Increase the padding to make the button bigger */
  z-index: 999;
}

.burger {
  width: 25px;
  height: 3px;
  background-color: #fff;
  margin: 5px 0;
  z-index: 999;
}

.menu {
  position: absolute;
  top: 0; /* Initially move the menu off-screen to the top */
  right: 0; /* Align the menu to the right side */
  background-color: #333;
  color: #fff;
  padding: 20px; /* Increase the padding to make the menu bigger */
  border-radius: 5px;
  transition: top 0.3s ease, opacity 0.3s ease; /* Add transition for top property */
  opacity: 0; /* Initially hide the menu */
  z-index: -1; /* Move the menu behind other elements */
  font-size: 18px; /* Increase the font size of the menu items */
}

.menuActive {
  top: 100%; /* Move the menu into view when active */
  opacity: 1; /* Show the menu */
  z-index: 999; /* Move the menu in front of other elements */
}

.menu ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.menu ul li {
  padding: 12px 0; /* Increase the padding to make the menu items bigger */
  cursor: pointer;
}

.menu ul li:hover {
  background-color: #555;
}

body.menuNotActive {
  overflow-x: hidden; /* Prevent horizontal scrolling when menu is not active */
}
