/* Popup.css */

.popup_overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.popup_container {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    z-index: 1000;
}

.popup {
    text-align: center;
    z-index: 1000;
}

.popup_button {
    margin-top: 10px;
    padding: 8px 16px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    z-index: 1000;
}

.popup_button:hover {
    background-color: #0056b3;
    z-index: 1000;
}

.close {
    background-color: #e3720d;
    padding: 10px 20px;
    border-radius: 5px;
    margin-top: 20px;
    color: #fff;
    border: none;
    cursor: pointer;
    z-index: 1000;
}
