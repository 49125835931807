/* Add this CSS rule to prevent horizontal scrolling */
body {
  height: 100%;
  overflow-x: hidden !important;
  width: 100%;
  position: relative;
  font-family: 'Poppins', sans-serif;
}

/* Header section */
.header {
  text-align: center;
  padding: 50px 20px; /* Adjust padding as needed */
  background-color: #f8f8f8; /* Background color for the header */
}

.headerTitle {
  font-size: 36px; /* Adjust font size as needed */
  margin-bottom: 20px; /* Add margin at the bottom */
}

.headerDescriptionContainer {
  max-width: 800px; /* Set maximum width for the description */
  margin: 0 auto; /* Center the container */
}

.headerDescription {
  font-size: 18px; /* Adjust font size as needed */
  line-height: 1.6; /* Adjust line height as needed */
}

.image {
  width: 600px; /* Adjust width as needed */
  height: auto;
  border-radius: 10px; /* Add rounded corners */
}

/* For mobile view */
@media only screen and (max-width: 768px) {
  .rectanglesContainer .rectangle {
    max-width: 100%;
    height: 500px;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
    position: relative;
    border: 2px solid #ffa500; /* Add orange border */
  }

  .priceContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Poppins', sans-serif;
  }

  

  .priceContainer h1 {
    font-family: 'Poppins', sans-serif;
    margin-right: 5px;
  }

  .priceContainer span {
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
  }

  ul li {
    border-top: 1px solid #e3720d; /* Add a solid border above each list item */
    border-bottom: 1px solid #e3720d; /* Add a solid border below each list item */
    padding: 10px 0; /* Add padding to space out the borders */
  }

  .rectangle h2,
  .rectangle h4,
  .rectangle h1,
  .rectangle p,
  .rectangle ul {
    font-family: 'Poppins', sans-serif;
    text-align: center;
    margin-left: 5px;
  }
}

/* For desktop view */
@media only screen and (min-width: 769px) {
  .rectanglesContainer {
    display: flex;
    font-family: 'Poppins', sans-serif;
    flex-wrap: wrap;
  }

  ul li {
    border-top: 1px solid #e3720d; /* Add a solid border above each list item */
    border-bottom: 1px solid #e3720d; /* Add a solid border below each list item */
    padding: 10px 0; /* Add padding to space out the borders */
  }

  .rectangle {
    flex: 1;
    font-family: 'Poppins', sans-serif;
    max-width: 20%;
    height: 500px;
    margin: 20px auto;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
    position: relative;
    border: 2px solid #e3720d; /* Add orange border */
    position: relative; /* Position the circle relative to the rectangle */
    border: 2px solid #e3720d; /* Add orange border */
    overflow: hidden; /* Hide overflow for circle */
  }

  /* Add border styles to list items */
  .list li {
    border-top: 1px solid #e3720d; /* Add a solid border above each list item */
    border-bottom: 1px solid #e3720d; /* Add a solid border below each list item */
    padding: 10px 0; /* Add padding to space out the borders */
  }

  .rectangle::before {
    content: '';
    position: absolute; /* Position the circle absolutely */
    top: -40px; /* Adjust the distance from the top */
    left: 50%; /* Align the circle horizontally */
    transform: translateX(-50%); /* Center the circle horizontally */
    width: 90px; /* Set the width of the circle */
    height: 90px; /* Set the height of the circle */
    background-color: #ffa500; /* Orange background color */
    border-radius: 50%; /* Make it a circle */
  }

  .rectangle:hover {
    transform: scale(1.02);
  }

  .rectangleContent {
    position: relative;
    font-family: 'Poppins', sans-serif;
    z-index: 2;
  }

  .priceContainer {
    display: flex;
    font-family: 'Poppins', sans-serif;
    align-items: center;
    justify-content: center;
  }

  .priceContainer h1 {
    margin-right: 5px;
    font-family: 'Poppins', sans-serif;
  }

  .priceContainer span {
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
  }

  .rectangle h2,
  .rectangle h4,
  .rectangle h1,
  .rectangle p,
  .rectangle ul {
    font-family: 'Poppins', sans-serif;
    text-align: center;
  }

  @media (hover: none) {
    .rectangle:hover {
      transform: none;
    }
  }
}

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap');

/* Apply Poppins font to all text elements */
body, h1, h2, h3, h4, h5, h6, p, a, span, button {
  font-family: 'Poppins', sans-serif;
}
