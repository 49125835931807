/* TermsOfUse.module.css */

.container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .title {
    text-align: center;
  }
  
  .content {
    margin-top: 20px;
  }
  
  .link {
    display: block;
    text-align: center;
    margin-top: 20px;
  }
  