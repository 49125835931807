/* App.css */

body {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f4f4f4;
  scroll-behavior: smooth; /* Add smooth scrolling */
  overflow-x: hidden;
}

.header {
  display: flex; /* Use flexbox to align items */
  align-items: center; /* Vertically center items */
  background-color: #333;
  color: #fff;
  padding: 20px;
  overflow-x: hidden;
}

.section {
  padding: 50px;
  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  margin: 20px auto;
  max-width: 800px;
  transition: transform 0.3s ease; /* Add transition for smooth transformation */
  position: relative; /* Ensure relative positioning for absolute children */
}

.section:hover {
  transform: scale(1.02); /* Example: Enlarge section on hover */
}

.background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1; /* Ensure the background image stays behind other content */
}

.background-image img {
  width: 100%;
  height: 100%;
  object-fit: fill; /* Stretch the image to fill the entire container */
  filter: brightness(50%); /* Reduce brightness to darken the image */
}


.sectionContent {
  position: relative; /* Ensure content stays above the background image */
  z-index: 2;
}

.section h2 {
  color: white;
  text-align: center; /* Center the heading */
}

.section p {
  color: white;
  line-height: 1.6;
}

/* Add more specific styles for each section if needed */

/* Improve touch responsiveness */
@media (hover: none) {
  .section:hover {
    transform: none; /* Disable hover effects on touch devices */
  }
}


